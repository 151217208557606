import { request } from "./common";

const API_URL = `${process.env.API_URL || "/api"}`;

/**
 * Helper method to get list of Jobs
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function getJobsList(data = {}, options = {}) {
  return request(`${API_URL}/posts/jobs/get-ads`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to get prices of all the real estate posts
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function getPrices(data, options = {}) {
  return request(`${API_URL}/posts/job/prices`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to get list of pricing discounts
 * @returns
 */
export function getPriceDiscounts() {
  return request(`${API_URL}/posts/price/discounts`);
}

/**
 * Helper method to get pricing details for the package
 * @returns
 */
export function getPriceDetails(data, options = {}) {
  return request(`${API_URL}/posts/price/details`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to get package list related to a user
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function getUserPackages(data, options = {}) {
  return request(`${API_URL}/user/packages`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to update user packages
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function purchaseAdUsingCredit(data, options = {}) {
  return request(`${API_URL}/user/update/packages`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to mark ad as active
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function activateAd(data, options = {}) {
  return request(`${API_URL}/ads/package/activate`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to get job categoris
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function getJobCategories(data, options = {}) {
  return request(`${API_URL}/jobs/main-categories`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to get job sub categories based on category
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function getJobSubCategories(data, options = {}) {
  return request(`${API_URL}/jobs/sub-categories`, {
    method: "POST",
    data,
    ...options,
  });
}


/**
 * Helper method to get job categoris
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function getStates(data, options = {}) {
  return request(`${API_URL}/jobs/states`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to get job sub categories based on category
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function getCities(data, options = {}) {
  return request(`${API_URL}/jobs/full-cities`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to create a new job post
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function createJobPost(data, options = {}) {
  return request(`${API_URL}/posts/jobs`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to update job post by id
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function updateJobPost(data, options = {}) {
  return request(`${API_URL}/posts/jobs/update`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to get job Ad by Id
 * @param {*} data
 * @returns
 */
export function getJobAdById(data) {
  return request(`${API_URL}/posts/jobs/job-by-id`, {
    method: "POST",
    data
  }
  );
}

/**
 * Helper method to delete a job post by id
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function deleteJobAdById(data, options = {}) {
  return request(`${API_URL}/posts/jobs/delete`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to delete a job post by id
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function deleteArchiveJobAdById(data, options = {}) {
  return request(`${API_URL}/posts/jobs/delete-archive`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to restore a job post
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function restoreAd(data, options = {}) {
  return request(`${API_URL}/posts/restore-ad`, {
    method: "POST",
    data,
    ...options,
  });
}

export function updateAlert(data, options = {}) {
  return request(`${API_URL}/posts/jobs/updateAlert`, {
    method: "POST",
    data,
    ...options,
  });
}

export function insertAlert(data, options = {}) {
  return request(`${API_URL}/posts/jobs/insertAlert`, {
    method: "POST",
    data,
    ...options,
  });
}

export function getAlert(data) {
  return request(`${API_URL}/posts/jobs/getAlert`, {
    method: "POST",
    data
  }
  );
}