<template>
    <header id="header-container" class="fullwidth not-sticky"
        :class="$nuxt.$data.layoutName == 'dashboard' ? 'dashboard-header' : ''">
        <!-- Header -->
        <div id="header">
            <div class="container">
                <!-- Left Side Content -->
                <div class="left-side">
                    <!-- Logo -->
                    <div id="logo">
                        <NuxtLink to="/"><img src="/images/logo/logo-blue.png" alt=""></NuxtLink>
                    </div>
                    <!-- Main Navigation -->
                    <nav id="navigation">
                        <ul id="responsive">
                            <NavLink v-for="menuItem in pages" :key="menuItem.name" :name="menuItem.name"
                                :to="menuItem.to">
                                <NavDropdown v-if="menuItem.children">
                                    <NavLink v-for="menuChildItem in menuItem.children" :key="menuChildItem.name"
                                        :name="menuChildItem.name" :to="menuChildItem.to">
                                        <NavDropdown v-if="menuChildItem.children">
                                            <NavLink v-for="menuChildItem2 in menuChildItem.children"
                                                :key="menuChildItem2.name" :name="menuChildItem2.name"
                                                :to="menuChildItem2.to">
                                            </NavLink>
                                        </NavDropdown>
                                    </NavLink>
                                </NavDropdown>
                            </NavLink>
                            <li>
                                <a target="_blank" href="https://www.macherusaevents.com">Job Fair 2025</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="clearfix"></div>
                    <!-- Main Navigation / End -->
                </div>
                <!-- Left Side Content / End -->
                <!-- Right Side Content / End -->
                <div class="right-side">
                    <!-- Post Add Button -->
                    <HeaderWidgetBase hideOnMobile>
                        <HeaderNotifications to="/pricing">
                            <template #trigger>
                                <button class="button ripple-effect">
                                    Post Your Ad
                                    <IconPlus class="icon"></IconPlus>
                                </button>
                            </template>
                        </HeaderNotifications>
                    </HeaderWidgetBase>
                    <!--  User Notifications -->
                    <!-- <HeaderWidgetBase hideOnMobile>
                            <HeaderNotifications>
                                <template #trigger>
                                    <IconBell></IconBell>
                                    <span>4</span>
                                </template>
                                <template #dropdown>
                                    <HeaderNotificationsDropdown name="Notifications">
                                    </HeaderNotificationsDropdown>
                                </template>
                            </HeaderNotifications>
                        </HeaderWidgetBase> -->
                    <!--  User Notifications / End -->
                    <!-- User Menu -->
                    <HeaderWidgetBase>
                        <HeaderNotifications v-if="loggedIn">
                            <template #trigger>
                                <div>
                                    <p>Hi, {{ user.name }}</p>
                                </div>
                            </template>
                            <template #dropdown>
                                <UserMenu></UserMenu>
                            </template>
                        </HeaderNotifications>
                        <HeaderNotifications :to="`/login?redirect=${encodeURIComponent($route.fullPath)}`" v-else>
                            <template #trigger>
                                <div>
                                    <IconLogin class="icon"> </IconLogin>
                                    Log In
                                </div>
                            </template>
                        </HeaderNotifications>

                    </HeaderWidgetBase>
                    <!-- Mobile Navigation Button -->
                    <span class="mmenu-trigger">
                        <button class="hamburger hamburger--collapse" type="button">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </span>
                </div>
                <!-- Right Side Content / End -->
            </div>
        </div>
        <!-- Header / End -->
    </header>
</template>

<script>
// Icons
import IconMail from "~/assets/icons/mail.svg"
import IconBell from "~/assets/icons/bell.svg"
import IconPlus from "~/assets/icons/plus.svg"
import IconLogin from "~/assets/icons/log-in.svg"
import IconAccount from 'vue-material-design-icons/Account.vue'

// Components
import NavLink from './navigation/NavLink.vue';
import NavDropdown from './navigation/NavDropdown.vue';
import UserMenu from './header-widget/UserMenu.vue';
import HeaderWidgetBase from './header-widget/Base.vue';
import HeaderNotifications from './header-widget/HeaderNotifications.vue';
import HeaderNotificationsDropdown from './header-widget/HeaderNotificationsDropdown.vue';

// Data
import pages from "~/content/core/pages.json"
import { mapState } from 'vuex'

export default {
    components: {
        IconMail,
        IconBell,
        IconPlus,
        IconLogin,
        IconAccount,
        NavLink,
        NavDropdown,
        UserMenu,
        HeaderWidgetBase,
        HeaderNotifications,
        HeaderNotificationsDropdown
    },
    data() {
        return {
            pages,
            externalLoaded: false,
            baseUrl: process.env.baseUrl
        }
    },

    computed: {
        ...mapState('auth', ['loggedIn', 'user']),

    },
    methods: {
        redirect(to) {
            console.log("redirect");
            this.$router.push(to);
        }
    },

    mounted() {
        if (process.browser) {
            initialCustom();
        }
    }
}
</script>

<style lang="scss" scoped>
.icon {
    position: relative;
    bottom: -2px;
}
</style>